<template>
  <div class="mt-7 text-center">
    <!-- <div class="text-primary text-xl text-bold mb-4">
      Thank You
    </div> -->
    <div
      class="text-md text-grey mb-4 p-4 thankyou-message"
      v-html="business.thank_you_text"
    />
    <!-- v-html="business.funeral_thank_you_msg" -->

    <!-- <s-button
      icon="vmdi-arrow-right"
      align="right"
      color="primary"
      label="You want fill another"
      :href="businessSlug"
    /> -->
  </div>
</template>
<script>
export default {
  props: {
    business: Object
  },
  mounted() {
    localStorage.clear();
  },
  computed: {
    businessSlug() {
      return (
        window.location.origin + "/app/#/" + (this.business?.url_slug || "")
      );
    }
  }
};
</script>
<style>
.thankyou-message {
  max-width: 768px;
  margin: 0 auto;
}
.thankyou-message p + p {
  margin-top: 10px;
}
</style>