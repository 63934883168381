<template>
  <div
    v-if="loading"
    class="p-5 flex flex--fit flex--center flex--middle h-100"
    v-shilp-loader="true"
  >
    <img src="img/logo-mini.png" height="40" width="40" class="mt-7" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    window.location.replace(window.location.origin + "/app/admin.html#/");
  },
};
</script>
