<template>
  <div
    v-if="loading"
    class="p-5 flex flex--fit flex--center flex--middle h-100"
    v-shilp-loader="true"
  >
    <img src="img/logo-mini.png" height="40" width="40" class="mt-7" />
  </div>
  <s-layout
    v-else
    class="responsive-layout"
    top-size="190px"
    ref="mainContainer"
    full-height
  >
    <!-- push-top -->
    <template #top>
      <company-profile :business="business" />
    </template>
    <template #default>
      <router-view :company-slug="companySlug" :business="business" />
    </template>
  </s-layout>
</template>

<script>
import { getDetailsUsingSlug } from "@/api/new-request/funeral";
export default {
  props: {
    companySlug: String
  },
  data() {
    return {
      loading: false,
      business: {}
    };
  },
  mounted() {
    this.loading = true;
    this.get();
  },
  components: {
    CompanyProfile: require("@/components/CompanyProfile").default
  },
  methods: {
    get() {
      return getDetailsUsingSlug(this.companySlug).then(res => {
        this.business = res;
        this.loading = false;
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
@media (max-width: 767px) {
  .responsive-layout {
    height: auto;
  }
}
</style>